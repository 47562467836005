angular
    .module('annexaApp')
    .component('annexaSendToSign', {
        templateUrl: './components/sign/annexa-send-to-sign/annexa-send-to-sign.html',
        controller:['Language', '$rootScope', 'SignLocalFactory', 'DialogsFactory', 'GlobalDataFactory', 'CacheFactory', 'ErrorFactory', '$filter', 'SignModals', 'AnnexaFormlyFactory', function (Language, $rootScope, SignLocalFactory, DialogsFactory, GlobalDataFactory, CacheFactory, ErrorFactory, $filter, SignModals, AnnexaFormlyFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.showRadioCircuitType = true;
            vm.presetCircuits = [];
            vm.signActionTypes = [];
            vm.actualCicuitType = 'PRESET';
            vm.esetMode = $rootScope.esetMode;
            vm.editCircuit = true;

            vm.result = {
                observations: '',
                urgentReason: '',
                urgent: false,
                circuit: {
                    type: 'PRESET',
                    selected: undefined,
                    custom: [],
                    addToFavorite: false,
                    favoriteName: ''
                }
            };

            vm.checkRequired = function($data, column) {
                if(column.required && !$data) {
                    return $filter('translate')('global.validation.required');
                }
            };

            var printActionType = function(item) {
                if(!item.signActionType || item.signActionType == -1) {
                    return 'global.documents.modal.sendToSign.empty';
                }

                var indexColumnSignActionTypes = $linq(vm.columnsCustomCircuit).indexOf("x => x.id == 'signActionType'");

                if(indexColumnSignActionTypes != -1) {
                    var selected = $linq(vm.columnsCustomCircuit[indexColumnSignActionTypes].list).singleOrDefault(undefined, "x => x.id == " + item.signActionType);

                    if(selected) {
                        return selected[vm.languageColumn];
                    }
                }

                return 'global.documents.modal.sendToSign.empty';
            };

            vm.addCustomCircuit = function() {
            	var modal = angular.copy(SignModals.addCustomCircuit);
            	modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { maxDurationDays: 5, signatureWithoutOrder:((vm.result.circuit.signatureWithoutOrder)?true:false) };
                var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
                if(diliStampTypeField) {
                	_.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                		if(value.certificateType == 'ORGAN') {
                			value.label = value[Language.getActiveColumn()];
	           				diliStampTypeField.templateOptions.options.push(value);
                		}
           			});
                }
            	var add = function() {
            		var model = this.annexaFormly.model.modal_body;
            		if(model.signActionType.code == 'THIRDSIGN') {
            			model.user = undefined;
            		} else {
            			model.third = undefined;
            			model.thirdAddress = undefined;
            		}
            		vm.result.circuit.custom.push({
                        actionOrder: vm.result.circuit.custom.length + 1,
                        user: model.user,
                        third: model.third,
                        signActionType: model.signActionType,
                        maxDurationDays: model.maxDurationDays,
                        thirdAddress: model.thirdAddress,
                        diligenceStampType: model.diligenceStampType,
                        subject: model.subject,
                        subjectAux: model.subjectAux
                    });
            		this.close();
            	};
            	AnnexaFormlyFactory.showModal("modalAddCustomCircuit", modal, add, false);
            };
            
            vm.editCustomCircuit = function($index) {
            	var modal = angular.copy(SignModals.addCustomCircuit);
            	modal.title = 'global.documents.modal.sendToSign.editUserThird';
            	var diliStampTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'diligenceStampType'");
                if(diliStampTypeField) {
                	_.forEach(GlobalDataFactory.diligenceStampTypes, function (value) {
                		if(value.certificateType == 'ORGAN') {
                			value.label = value[Language.getActiveColumn()];
               				diliStampTypeField.templateOptions.options.push(value);
               				if(vm.result.circuit.custom[$index].diligenceStampType && vm.result.circuit.custom[$index].diligenceStampType.id == value.id) {
               					vm.result.circuit.custom[$index].diligenceStampType = value;
                            }
                		}
           			});
                }
            	modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = angular.copy(vm.result.circuit.custom[$index]);
				modal.annexaFormly.model.modal_body.signatureWithoutOrder = ((vm.result.circuit.signatureWithoutOrder)?true:false);
            	var add = function() {
            		var model = this.annexaFormly.model.modal_body;
            		if(model.signActionType.code == 'THIRDSIGN') {
            			model.user = undefined;
            		} else {
            			model.third = undefined;
            			model.thirdAddress = undefined;
            		}
            		vm.result.circuit.custom[$index] = {
                        actionOrder: vm.result.circuit.custom[$index].actionOrder,
                        user: model.user,
                        third: model.third,
                        signActionType: model.signActionType,
                        maxDurationDays: model.maxDurationDays,
                        thirdAddress: model.thirdAddress,
                        diligenceStampType: model.diligenceStampType,
                        subject: model.subject,
                        subjectAux: model.subjectAux
                    };
            		this.close();
            	};
            	AnnexaFormlyFactory.showModal("modalAddCustomCircuit", modal, add, false);
            };

            vm.removeCustomCircuit = function($index) {
                for(var i = $index; i < vm.result.circuit.custom.length; i++) {
                    vm.result.circuit.custom[i].actionOrder = vm.result.circuit.custom[i].actionOrder -1;
                }

                vm.result.circuit.custom.splice($index, 1);
            };

            vm.columnsCustomCircuit = [
                { id: 'actionOrder', style: 'width:17%;', name: 'global.documents.modal.sendToSign.order', type: 'label', min: function () { return 1; }, max: function () { return vm.circuit.custom.length; }, required: true },
                { id: 'user', style: 'width:25%;', name: 'global.documents.modal.sendToSign.user', required: true, type: 'loadUser' },
                { id: 'signActionType', style: 'width:25%;', name: 'global.documents.modal.sendToSign.signType', required: true, type: 'select', nameProperty: vm.languageColumn, list: [], print: printActionType },
                { id: 'maxDurationDays', style: 'width:17%', name: 'global.documents.modal.sendToSign.days', type: 'number', min: function () { return 1; }, max: function () { return 365; }, required: true },
                { id: 'actions', style: 'width:16%', name: '', type: 'actions' }
            ];

            vm.changeCircuitType = function() {
                if(vm.actualCicuitType != vm.result.circuit.type) {
                    vm.actualCicuitType = vm.result.circuit.type;
                    vm.result.circuit.custom = [];
                    vm.result.circuit.selected = undefined;
                    vm.result.circuit.addToFavorite = false;
                    vm.result.circuit.favoriteName = '';
					vm.result.circuit.signatureWithoutOrder = false;
                    vm.result.circuit.edit = false;
                    vm.isEditCircuit = false;
					if(vm.result.circuit.type == 'CUSTOM'){
						vm.showSignatureWithoutOrder = true;
					}else{
						vm.showSignatureWithoutOrder = false;
					}
                }
            };

            vm.customizePreset = function() {
                vm.actualCicuitType = 'CUSTOM';
                vm.result.circuit.type = 'CUSTOM';
                vm.result.circuit.custom = [];
				vm.result.circuit.signatureWithoutOrder = vm.result.circuit.selected.signatureWithoutOrder;
                vm.isEditCircuit = true;
				vm.showSignatureWithoutOrder = true;
                _.forEach($linq(vm.result.circuit.selected.presetSignActions).orderBy("x => x.id").toArray(), function(item) {
                	var user;
                	var third;
                	var thirdAddress;
                	if(item.user) {
                		user = {
                				id: item.user.id, 
                				user: {
                					id: item.user.id, 
                					name: item.user.name, 
                					surename1: item.user.surename1, 
                					surename2: item.user.surename2
                				}, 
                				value: item.user.name + ' ' + item.user.surename1 +(item.user.surename2 ? ' ' + item.user.surename2 : '')};
                	}
                	if(item.third) {
                        third = { 
                        		id:  item.third.id,
                        		third: {
                                		id: item.third.id,
                                		name: item.third.name,
                        				surename1: item.third.surename1, 
                        				surename2: item.third.surename2,
                        				corporateName: item.third.corporateName
                                }, 
                        		value: item.third.value 
                        }
                	}
                	if(item.thirdAddress) {
                		thirdAddress = {
                				id: item.thirdAddress.id,
                				telematicValue: item.thirdAddress.telematicValue
                		}
                	}
                    vm.result.circuit.custom.push({
                        actionOrder: item.actionOrder,
                        user: user,
                        signActionType: item.signActionType,
                        maxDurationDays: item.maxDurationDays,
                        third: third,
                        thirdAddress: thirdAddress,
                        diligenceStampType: item.diligenceStampType,
                        subject: item.subject,
                        subjectAux: item.subjectAux
                    });
                });
            };
            
            vm.checkChange = function(check) {
            	vm.result.circuit.favoriteName = '';
            	if(check == 'edit') {
            		vm.result.circuit.addToFavorite = false;
            	} else if(check == 'favourite') {
            		vm.result.circuit.edit = false;
            	}
            }
            
            vm.deleteCustomCircuit = function() {
            	DialogsFactory.confirm('global.documents.modal.sendToSign.confirmDeleteCustomCircuitTitle', 'global.documents.modal.sendToSign.confirmDeleteCustomCircuitContent')
	                .then(function () {
	                    $rootScope.loading(true);
	                    SignLocalFactory.deleteSignCircuit(vm.result.circuit.selected.id)
	                    .then(function () {
	                            $rootScope.loading(false);
	                            var index = $linq($rootScope.LoggedUser.presetSignCircuits).indexOf("x => x.id == " + vm.result.circuit.selected.id);
	                            if (index > -1) {
	                            	$rootScope.LoggedUser.presetSignCircuits.splice(index, 1);
                            	}
	                            vm.actualCicuitType = vm.result.circuit.type;
	                            vm.result.circuit.custom = [];
	                            vm.result.circuit.selected = undefined;
	                            vm.result.circuit.addToFavorite = false;
	                            vm.result.circuit.favoriteName = '';
	                            vm.result.circuit.edit = false;
								vm.result.circuit.signatureWithoutOrder = false;
	                            vm.isEditCircuit = false;
	                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('LoggedUserData')) {
	                            	var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
	                                cache.userObject.presetSignCircuits = $rootScope.LoggedUser.presetSignCircuits;
	                                CacheFactory.get('globalDataCache').remove('LoggedUserData');
	                                CacheFactory.get('globalDataCache').put('LoggedUserData', cache);
	                            }
	                            if(GlobalDataFactory.presetSignCircuit){
	                            	GlobalDataFactory.presetSignCircuit.length = 0;
	                            }else{
	                            	GlobalDataFactory.presetSignCircuit = [];
	                            }
	                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
	                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit')
	                            }
	                            GlobalDataFactory.loadGlobalData();
	                            loadPresetSignCircuits();
	                        }).catch(function (error) {
	                            $rootScope.loading(false);
	                            DialogsFactory.error(ErrorFactory.getErrorMessage('documents', 'checkDelete', error.data ), $filter('translate')('DIALOGS_ERROR_MSG'));
	                    });
	                });
            }

            vm.updateCustomCircuit = function($data) {
                //Empty
            };

            var loadPresetSignCircuits = function () {
                var getSignActionUserBySectionLevel = function (section, level, users) {
                    var user ;

                    if(section && level) {
                        var addUser = { id: undefined };

                        if(section.responsible) {
                            addUser = section.responsible;
                        }

                        if(section.parent) {
                            users.push(addUser);
                            user = getSignActionUserBySectionLevel(section.parent, level, users);
                        } else {
                            users.push(addUser);

                            if(users.length >= (level - 1)) {
                                user = users[level - 1];
                            }
                        }
                    }

                    if(user && !user.id) {
                        user = undefined;
                    }

                    return user;
                };

                var fixPresetSignCircuit = function (presetSignCircuit, section) {
                    var addCircuit = true;

                    _.forEach(presetSignCircuit.presetSignActions, function (psaItem) {
                        if(psaItem.signatoryType.id != $rootScope.app.configuration.signatory_type_user.value) {
                            psaItem.user = getSignActionUserBySectionLevel(section, psaItem.actionLevel, []);

                            if(!psaItem.user) {
                                addCircuit = false;
                            }
                        }
                    });

                    return {
                        add: addCircuit,
                        circuit: presetSignCircuit
                    };
                };

                var getDocumentTypeCircuits = function(documentType, section, circuits) {
                    var documentTypeCircuits = [];

                    if(documentType.presetSignCircuits) {
                        _.forEach(documentType.presetSignCircuits, function (pscItem) {
                            if($linq(circuits).count("x => x.id == " + pscItem.id) == 0) {
                                var fixedCircuit = fixPresetSignCircuit(pscItem, section);

                                if (fixedCircuit.add) {
                                    documentTypeCircuits.push(fixedCircuit.circuit);
                                }
                            }
                        });
                    }

                    return documentTypeCircuits;
                };

                var isTemplateBased = function(document) {
                    if(document.template || document.tramTemplate || document.secTemplate) {
                        return true;
                    }

                    return false;
                };

                var getEditCircuit = function(document) {
                    if(isTemplateBased(document)) {
                        var templateProperty = getTemplateProperty(document);

                        if(templateProperty) {
                            return document[templateProperty].editPresetSignCircuit;
                        }

                        return false;
                    } else {
                        return document.type.editPresetSignCircuit;
                    }
                };

                var hasTemplateCircuits = function(document) {
                    var templateProperty = getTemplateProperty(document);

                    if(templateProperty) {
                        return document[templateProperty].presetSignCircuits && document[templateProperty].presetSignCircuits.length > 0;
                    }

                    return false;
                };

                var getTemplateProperty = function (document) {
                    if(document.template) {
                        return 'template';
                    }

                    if(document.tramTemplate) {
                        return 'tramTemplate';
                    }

                    if(document.secTemplate) {
                        return 'secTemplate';
                    }
                };

                var getTemplateCircuits = function(document, section, circuits) {
                    var documentTemplateCircuits = [];

                    var templateProperty = getTemplateProperty(document);

                    _.forEach(document[templateProperty].presetSignCircuits, function (pscItem) {
                        if($linq(circuits).count("x => x.id == " + pscItem.id) == 0) {
                            var fixedCircuit = fixPresetSignCircuit(pscItem, section);
                            
                            if(fixedCircuit.add) {
                                documentTemplateCircuits.push(fixedCircuit.circuit);
                            }
                        }
                    });

                    return documentTemplateCircuits;
                };

                vm.presetCircuits = [];

                _.forEach(vm.documents, function(dItem) {
                	var documentCacSign = false
                	if(!dItem.signProcesses){
                		documentCanSign = true;
                	}else{
                		var processSignPending = $linq(dItem.signProcesses).where("x=>x.signState == 'PENDING' || x.signState == 'IDLE'").toArray();
                		if(!processSignPending || processSignPending.length == 0){
                			documentCanSign = true;
                		}
                		
                	}
                    if(documentCanSign) {
                        var documentPresetCircuits = [];

                        if(isTemplateBased(dItem) && hasTemplateCircuits(dItem)) {
                            documentPresetCircuits = getTemplateCircuits(dItem, dItem.section, documentPresetCircuits);
                        } else if((isTemplateBased(dItem) && !hasTemplateCircuits(dItem)) || !isTemplateBased(dItem)) {
                            documentPresetCircuits = getDocumentTypeCircuits(dItem.type, dItem.section, documentPresetCircuits);
                        }

                        vm.editCircuit = vm.editCircuit && getEditCircuit(dItem);

                        if(vm.presetCircuits.length == 0) {
                            vm.presetCircuits = documentPresetCircuits;
                        } else {
                            var distincs = $linq(documentPresetCircuits).except(vm.presetCircuits, "(x, y) => x.id == y.id").toArray();

                            vm.presetCircuits.push.apply(vm.presetCircuits, distincs);
                        }
                    }
                });

                if(vm.editCircuit) {
                    var userCircuits = $linq($rootScope.LoggedUser.presetSignCircuits).select(function(x) {
                        return {
                            id: x.id,
                            language1: x.language1,
                            language2: x.language2,
                            language3: x.language3,
							userCircuit: true,
							signatureWithoutOrder: x.signatureWithoutOrder,
                            presetSignActions: $linq(x.presetSignActions).select(function(y) {
                            	var user;
                            	var third;
                            	var thirdAddress;
                            	if(y.user && y.user.id && y.user.name && y.user.surename1) {
                            		user = { 
                            				id: y.user.id, 
                            				name: y.user.name, 
                            				surename1: y.user.surename1, 
                            				surename2: y.user.surename2 
                            		};
                            	} else if (y.relatedUser && y.relatedUser.id && y.relatedUser.name && y.relatedUser.surename1 && y.relatedUser.surename2) {
                            		user = {
                            				id: y.relatedUser.id, 
                            				name: y.relatedUser.name, 
                            				surename1: y.relatedUser.surename1, 
                            				surename2: y.relatedUser.surename2 
                            		};
                            	}
                            	if(y.third && y.third.id && ((y.third.name && y.third.surename1) || (y.third.corporateName))) {
                            		var name = '';
                                    if(y.third.corporateName) {
                                        name = y.third.corporateName;
                                    } else {
                                        name = y.third.name + ' ' + y.third.surename1;
                                    }

                                    if(y.third.surename2) {
                                        name += ' ' + y.third.surename2;
                                    }
                                    third = {
                                    		id: y.third.id,
                                    		name: y.third.name,
                            				surename1: y.third.surename1, 
                            				surename2: y.third.surename2,
                            				corporateName: y.third.corporateName,
                            				value: name
                                    }
                            	}
                            	if(y.thirdAddress && y.thirdAddress.id && y.thirdAddress.telematicValue) {
                            		thirdAddress = {
                            				id: y.thirdAddress.id,
                            				telematicValue: y.thirdAddress.telematicValue
                            		}
                            	}
                                return {
                                    id: y.id,
                                    actionLevel: y.actionLevel,
                                    actionOrder: y.actionOrder,
                                    maxDurationDays: y.maxDurationDays,
                                    signActionType: y.signActionType,
                                    signatoryType: y.signatoryType,
                                    user: user,
                                    third: third,
                                    thirdAddress: thirdAddress,
                                    diligenceStampType: y.diligenceStampType,
                                    subject: y.customText,
                                    subjectAux: y.customText
                                };
                            }).toArray()
                        };
                    }).toArray();

                    vm.presetCircuits.push.apply(vm.presetCircuits, userCircuits);
                }


            };

            this.$onInit = function() {
				vm.showSignatureWithoutOrder = false;
                if(vm.documents) {
                    SignLocalFactory.getSignActionTypes()
                        .then(function(data) {
                            var indexColumnSignActionTypes = $linq(vm.columnsCustomCircuit).indexOf("x => x.id == 'signActionType'");

                            if(indexColumnSignActionTypes != -1) {
                                vm.columnsCustomCircuit[indexColumnSignActionTypes].list = data;
                            }

                            loadPresetSignCircuits();

                            if(vm.presetCircuits.length == 0){
                                vm.result.circuit.type = 'CUSTOM';
								vm.result.circuit.signatureWithoutOrder = false;
                            }
                        });
                }
            };
        }],
        bindings: {
            documentIds: '=?',
            documents: '=?',
            result: '='
        }
    });